import React from 'react'

function Default() {
    return (
        <>
            <div className="listSec">
                <div className="card">
                    <p className='cardName'>Let's Roll up some PYQ's</p>
                    <p className='cardYear'>Select from menu<span>Pageroll</span></p>
                </div>
            </div>
        </>
    );
}

export default Default